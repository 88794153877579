import React from 'react';

const SystemDashboard = () => {
    return (
        <div>
            Dashboard for role System
        </div>
    );
};

export default SystemDashboard;