import UserDepartment from "./UserDepartment";

const DepartmentTab = () => {

    
    return (
        <>
            <UserDepartment />
        </>
    )
};

export default DepartmentTab;