import React from 'react';

const AdminDashboard = () => {
    return (
        <div>
            Anda login sebagai Admin
        </div>
    );
};

export default AdminDashboard;